import modules from './modules'
import VuexPersistence from 'vuex-persist'
import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  modules: modules,
  plugins: [vuexLocal.plugin]
})
// // 刷新加载的方法
// const refresh = function () {
//   // 页面刷新重新加载路由
//   store.commit('add_Routes_Fresh')
// }
// refresh()
export default store
