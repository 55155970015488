import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirect: '/homepage'
  },
  {
    path: '/homepage',
    component: resolve => require(['@/views/homepage/homepage.vue'], resolve),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/newslist',
    component: resolve => require(['@/views/news/newslist.vue'], resolve),
    meta: {
      title: '新闻列表'
    }
  }, {
    path: '/news',
    component: resolve => require(['@/views/news/news.vue'], resolve),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/news1',
    component: resolve => require(['@/views/news/news1.vue'], resolve),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/news2',
    component: resolve => require(['@/views/news/news2.vue'], resolve),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/newsaboutus',
    component: resolve => require(['@/views/homepage/newsaboutus.vue'], resolve),
    meta: {
      title: '公司简介'
    }
  },
  {
    path: '/newsyuanjing',
    component: resolve => require(['@/views/homepage/newsyuanjing.vue'], resolve),
    meta: {
      title: '愿景与使命'
    }
  },
  {
    path: '/newssoft',
    component: resolve => require(['@/views/homepage/newssoft.vue'], resolve),
    meta: {
      title: '软件'
    }
  },
  {
    path: '/newszhaonai',
    component: resolve => require(['@/views/homepage/newszhaonai.vue'], resolve),
    meta: {
      title: '招纳贤士'
    }
  },
  {
    path: '/newshard',
    component: resolve => require(['@/views/homepage/newshard.vue'], resolve),
    meta: {
      title: '硬件'
    }
  },
  {
    path: '/newsservice',
    component: resolve => require(['@/views/homepage/newsservice.vue'], resolve),
    meta: {
      title: '服务'
    }
  },
  {
    path: '/newsgover',
    component: resolve => require(['@/views/homepage/newsgover.vue'], resolve),
    meta: {
      title: '政府单位'
    }
  },
  {
    path: '/newsfinance',
    component: resolve => require(['@/views/homepage/newsfinance.vue'], resolve),
    meta: {
      title: '金融行业'
    }
  },
  {
    path: '/newscompony',
    component: resolve => require(['@/views/homepage/newscompony.vue'], resolve),
    meta: {
      title: '企业公司'
    }
  },
  {
    path: '/traceinfo',
    component: resolve => require(['@/views/traceInfo/traceInfo.vue'], resolve),
    meta: {
      title: '流程详情'
    }
  },
  {
    path: '/login',
    component: resolve => require(['@/views/login/login.vue'], resolve),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    component: resolve => require(['@/views/register/register.vue'], resolve),
    hidden: true,
  },
  {
    path: '/registercom',
    component: resolve => require(['@/views/register/registercom.vue'], resolve),
    hidden: true,
  },
  {
    path: '/404',
    component: resolve => require(['@/components/common/404.vue'], resolve),
    meta: {
      title: '404'
    }
  },
  {
    path: '/500',
    component: resolve => require(['@/components/common/500.vue'], resolve),
    meta: {
      title: '500'
    }
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/index',
    name: '首页',
    component: resolve => require(['@/views/home/Home.vue'], resolve),
    meta: {
      title: '首页'
    },
    children: [{
        path: '/',
        redirect: '/dashboard'
      },

      {
        path: '/dashboard',
        component: resolve => require(['@/views/dashboard/dashboard.vue'], resolve),
        meta: {
          title: '数据看板'
        }
      },
      {
        path: '/user',
        component: resolve => require(['@/views/user/user.vue'], resolve),
        meta: {
          title: '用户管理'
        }
      },
      {
        path: '/role',
        component: resolve => require(['@/views/role/role.vue'], resolve),
        meta: {
          title: '角色管理'
        }
      },
      {
        path: '/menu',
        component: resolve => require(['@/views/menu/menu.vue'], resolve),
        meta: {
          title: '菜单管理'
        }
      },
      {
        path: '/dept',
        component: resolve => require(['@/views/dept/dept.vue'], resolve),
        meta: {
          title: '企业管理'
        }
      },
      {
        path: '/system/dict',
        component: resolve => require(['@/views/system/dict/dict.vue'], resolve),
        meta: {
          title: '字典管理'
        }
      },
      {
        path: '/system/client',
        component: resolve => require(['@/views/system/client/client.vue'], resolve),
        meta: {
          title: '终端管理'
        }
      },
      {
        path: '/system/log',
        component: resolve => require(['@/views/system/log/log.vue'], resolve),
        meta: {
          title: '日志管理'
        }
      },
      {
        path: '/system/token',
        component: resolve => require(['@/views/system/token/token.vue'], resolve),
        meta: {
          title: '会话管理'
        }
      },
      {
        path: '/system/file',
        component: resolve => require(['@/views/system/file/file.vue'], resolve),
        meta: {
          title: '文件管理'
        }
      },
      {
        path: '/trace/product',
        component: resolve => require(['@/views/trace/product/product.vue'], resolve),
        meta: {
          title: '产品管理'
        }
      },
      {
        path: '/trace/brand',
        component: resolve => require(['@/views/trace/brand/brand.vue'], resolve),
        meta: {
          title: '品牌管理'
        }
      },
      {
        path: '/trace/category',
        component: resolve => require(['@/views/trace/category/category.vue'], resolve),
        meta: {
          title: '分类管理'
        }
      },
      {
        path: '/trace/attr',
        component: resolve => require(['@/views/trace/attr/attr.vue'], resolve),
        meta: {
          title: '属性管理'
        }
      },
      {
        path: '/back/process',
        component: resolve => require(['@/views/back/process/process.vue'], resolve),
        meta: {
          title: '流程管理'
        }
      },
      {
        path: '/back/business',
        component: resolve => require(['@/views/back/business/business.vue'], resolve),
        meta: {
          title: '行业管理'
        }
      },
      {
        path: '/back/template',
        component: resolve => require(['@/views/back/template/template.vue'], resolve),
        meta: {
          title: '模板管理'
        }
      },
      {
        path: '/print',
        component: resolve => require(['@/views/print/print.vue'], resolve),
        meta: {
          title: '打印管理'
        }
      },
    ]
  },
]




const router = new VueRouter({
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  var token = localStorage.getItem('token')
  if (to.path === '/login') {
    if (!token) {
      next()
    } else {
      let currentmenu = localStorage.getItem('vuex');
      //console.log(currentmenu);
      const objCurrentmenu = JSON.parse(currentmenu);
      //console.log(objCurrentmenu);
      if (objCurrentmenu && objCurrentmenu.menu && objCurrentmenu.menu.nowMenuList) {
        let {
          path
        } = objCurrentmenu.menu.nowMenuList
        next({
          path: path
        })
      }
    }
  } else {
    // 如果没登录,都导向登录页
    if (!token) {
      if (to.path.indexOf('/traceinfo') > -1 ||to.path.indexOf('/news') > -1|| to.path === '/homepage' || to.path === '/register' || to.path ==='/registercom') {
        next()
      } else if (to.path !== '/login') {
        next({
          path: '/login'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
  if (to.path) {
    window.localStorage.setItem('routeName', to.path.split('/')[1])
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router