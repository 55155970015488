import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'


import './plugins'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css'
import './assets/style/reset.scss'
import './assets/style/layout.scss'

Vue.config.productionTip = false

Vue.use(ElementUI)

// 监听localstorage的方法
Vue.prototype.$addStorageEvent = function (type, key, data) {
  if (type === 1) {
    // 创建一个StorageEvent事件
    let newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val)
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, data)
  } else {
    // 创建一个StorageEvent事件
    let newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val)
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, data)
  }
}

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  components: { App },
  data () {
    return {
      THEME_NAME: 'dark'
    }
  },
  mounted () {
    if (window.localStorage.getItem('YH_BF_THEME')) {
      this.THEME_NAME = window.localStorage.getItem('YH_BF_THEME').replace('theme-', '')
    }
    window.addEventListener('setItem', e => {
      this.THEME_NAME = e.newValue.replace('theme-', '')
    })
  }
}).$mount('#app')
