<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
// import { logout } from "@/api/lib/api.js";
export default {
  data() {
    return {
      beforeUnload_time: null,
      gap_time: null,
      isRefresh: true,
      theme: "theme-light",
    };
  },
  methods: {
    beforeunloadHandler() {
      this.beforeUnload_time = new Date().getTime();
    },

    /** 窗口关闭时退出登录关闭websocket连接 */
    unloadHandler(e) {
      this.gap_time = new Date().getTime() - this.beforeUnload_time;
      // 判断是窗口关闭还是刷新
      if (this.gap_time <= 5) {
        // localStorage.removeItem("vuex");
        // this.$store.dispatch("clear_user");
        // localStorage.removeItem("vuex");
      } 
    },
  },
  created() {
    if (window.localStorage.getItem("YH_BF_THEME")) {
      this.theme = window.localStorage.getItem("YH_BF_THEME");
    }
    document.body.classList.add(this.theme);
  },
  mounted() {
    // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // window.addEventListener('unload', e => this.unloadHandler(e))
    // window.addEventListener('setItem', e => { this.theme = e.newValue })
  },
  destroyed() {
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  watch: {
    theme(val, oldVal) {
      document.body.classList.remove(oldVal);
      document.body.classList.add(val);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  display: flex;
}
</style>