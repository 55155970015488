/**
 * 缓存菜单信息
 *
 */
const addUserInfo = {
  state: {
    realname: '',
    dept_id: '',
    user_id: '',
    username: '',
    gender: null,

  },
  mutations: {
    addUserInfo (state, param) {
      if (param) {
        state.realname = param.realname
        state.dept_id = param.deptId
        state.user_id = param.id
        state.username = param.username
        state.gender = param.gender
        state.avatar = param.avatar
      }
    },
    clear_user (state) {
      state.realname = ''
      state.dept_id = ''
      state.user_id = ''
      state.username = ''
      state.gender = null
      state.avatar=null
    }
  },
  actions: {
    add_UserInfo (context, param) {
      context.commit('add_UserInfo', param)
    },
    clear_user (context, param) {
      context.commit('clear_user', param)
    }
  }
}
export default addUserInfo
